import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-signing', 'TITLE', 'Digitālā parakstīšana');
Labels.set(
    'ui-signing',
    'DIGIPASS_CHALLENGE',
    'Izvēlies kodu kalkulatorā „APPLI 2” funkciju un ievadi šo kodu'
);
Labels.set(
    'ui-signing',
    'DIGIPASS_PASSWORD',
    'Kodu kalkulatora ģenerētais kods'
);
Labels.set('ui-signing', 'DIGIPASS_PLACEHOLDER', 'APPLI 2');
Labels.set('ui-signing', 'DIGIPASS_CONFIRM', 'Apstiprināt');
Labels.set(
    'ui-signing',
    'DIGIPASS_ERROR_MESSAGE',
    'Neizdevās apstiprināt ar kodu kalkulatoru.'
);

Labels.set(
    'ui-signing',
    'SMART_ID_RUNNING',
    'Paraksti ar Smart-ID. Salīdzini kontroles kodu!'
);
Labels.set('ui-signing', 'SMART_ID_CHALLENGE', 'Tavs kontroles kods:');
Labels.set(
    'ui-signing',
    'SMART_ID_INTRO_MESSAGE',
    'Pārliecinies, ka piekrīti informācijai, ko paraksti. Ja šaubies, vari atgriezties iepriekšējā solī un pārbaudīt dokumenta saturu.'
);
Labels.set('ui-signing', 'SMART_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SMART_ID_SENDING_MESSAGE',
    'Pieprasījums ir nosūtīts uz tavu viedierīci. Salīdzini kontroles kodu un ievadi PIN2.'
);

Labels.set(
    'ui-signing',
    'MOBILE_ID_RUNNING',
    'Paraksti ar Mobile-ID. Salīdzini kontroles kodu!'
);
Labels.set('ui-signing', 'MOBILE_ID_CHALLENGE', 'Tavs kontroles kods:');
Labels.set(
    'ui-signing',
    'MOBILE_ID_INTRO_MESSAGE',
    'Pārliecinies, ka piekrīti informācijai, ko paraksti. Ja šaubies, vari atgriezties iepriekšējā solī un pārbaudīt dokumenta saturu.'
);
Labels.set('ui-signing', 'MOBILE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'MOBILE_ID_SENDING_MESSAGE',
    'Pieprasījums ir nosūtīts uz tavu viedierīci. Salīdzini kontroles kodu un ievadi PIN2.'
);

Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_RUNNING',
    'Paraksti ar eParaksts mobile. Salīdzini kontroles kodu!'
);
Labels.set('ui-signing', 'EPARAKSTS_MOBILE_CHALLENGE', 'Tavs kontroles kods:');
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_INTRO_MESSAGE',
    'Pārliecinies, ka piekrīti informācijai, ko paraksti. Ja šaubies, vari atgriezties iepriekšējā solī un pārbaudīt dokumenta saturu.'
);
Labels.set('ui-signing', 'EPARAKSTS_MOBILE_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_SENDING_MESSAGE',
    'Pieprasījums ir nosūtīts uz tavu eParaksts mobile viedierīci. Salīdzini kontroles kodu un ievadi PIN2.'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_ERROR_MESSAGE',
    'Parakstīšana ar eParaksts mobile neizdevās.'
);

Labels.set(
    'ui-signing',
    'SIMPLE_ID_RUNNING',
    'Apstiprini ar biometriju vai PIN kodu. Salīdzini kontroles kodu!'
);
Labels.set('ui-signing', 'SIMPLE_ID_CHALLENGE', 'Tavs kontroles kods:');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_INTRO_MESSAGE',
    'Pārliecinies, ka piekrīti informācijai, ko apstiprini. Ja šaubies, vari atgriezties iepriekšējā solī un pārbaudīt dokumenta saturu.'
);
Labels.set('ui-signing', 'SIMPLE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_SENDING_MESSAGE',
    'Pieprasījums ir nosūtīts uz tavu viedierīci. Salīdzini kontroles kodu un apstiprini ar biometriju vai PIN kodu.'
);

Labels.set('ui-signing', 'CANCEL_BUTTON_LABEL', 'Atpakaļ');
Labels.set('ui-signing', 'CONFIRM_BUTTON_LABEL', 'Apstiprināt');

Labels.set(
    'ui-signing',
    'SMART_ID_ERROR_MESSAGE',
    'Parakstīšana ar Smart-ID neizdevās.'
);
Labels.set(
    'ui-signing',
    'MOBILE_ID_ERROR_MESSAGE',
    'Neizdevās parakstīt ar Mobile-ID.'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_ERROR_MESSAGE',
    'Neizdevās apstiprināt ar biometriju vai PIN kodu.'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_ERROR_MESSAGE',
    'Parakstīšana ar eID karti neizdevās.'
);

Labels.set('ui-signing', 'SMART_ID_BUTTON_LABEL', 'Parakstīt ar Smart-ID PIN2');
Labels.set(
    'ui-signing',
    'MOBILE_ID_BUTTON_LABEL',
    'Parakstīt ar Mobile-ID PIN2'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_BUTTON_LABEL',
    'Parakstīt ar eParaksts mobile PIN2'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_BUTTON_LABEL',
    'Apstiprināt ar biometriju vai PIN kodu'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_BUTTON_LABEL',
    'Parakstīt ar ID kartes PIN2'
);
Labels.set('ui-signing', 'DIGIPASS_BUTTON_LABEL', 'Apstiprināt');

Labels.set(
    'ui-signing',
    'HWCRYPTO_DEFAULT_ERROR',
    'eID kartes spraudnis nedarbojas. Pārstartē pārlūku un mēģini vēlreiz. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_CERTIFICATES',
    'Netika atrasts derīgs eID kartes sertifikāts. Izņem karti no lasītāja, ievieto to atkal un mēģini vēlreiz. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_USER_CANCEL',
    'Mēģinājums pievienoties tika pārtraukts, jo tu nospiedi pogu "Atcelt".'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_DRIVER_ERROR',
    'eID starpprogrammatūras kļūda. Pārbaudi, vai eID starpprogrammatūra ir atjaunināta, pārstartē pārlūku un mēģini vēlreiz. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_IMPLEMENTATION',
    'eID kartes spraudņa kļūda. Pārbaudi, vai eID starpprogrammatūra ir atjaunināta un interneta pārlūka uzstādījumos ir aktivizēts spraudnis "Web eID" vai "eParaksts signing extension". Pēc tam pārstartē pārlūku un mēģini vēlreiz.'
);

Labels.set('ui-signing', 'LINK_PLACEHOLDER', 'Izlasi papildus instrukcijas');
